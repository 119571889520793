import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const IllustrationCategoryArchive = ({ pageContext, data }) => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".col-3",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.3 }
    )
  }, [])

  const {
    allContentfulIllustration: { edges: illustrations },
  } = data
  return (
    <Layout>
      <SEO
        title={pageContext.name + "カテゴリーのイラスト一覧"}
        description={
          "しょうのまきの" +
          pageContext.name +
          "のイラストレーションの制作実績はこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        }
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="page-head">
        <Title title={pageContext.name} />
      </header>

      <article className="post-content page-template no-image">
        <div className="row no-gutter">
          {illustrations.map(b => (
            <div className="col-3 post-archive">
              <Link
                className="post-archive-link"
                to={`/illustration/${b.node.slug}`}
                key={b.node.id}
              >
                {b.node.heroImage ? (
                  <Img
                    className="kg-image"
                    fluid={b.node.heroImage ? b.node.heroImage.fluid : null}
                    alt={b.node.title}
                  />
                ) : (
                  <Img
                    className="kg-image"
                    fluid={data.file.childImageSharp.fluid}
                    alt="イラストレーションの画像"
                  />
                )}

                <div className="post-archive-content">
                  <h2 className="post-archive-title">
                    {b.node.title} <span>...more</span>
                  </h2>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <ul className="actions">
          {illustrations.length > 16
            ? Array.from({ length: pageContext.numPages }).map(index => (
                <li
                  key={index}
                  isCurrentPage={index + 1 === pageContext.currentPage}
                >
                  <Link
                    className={"button primary"}
                    to={
                      index === 0
                        ? "/illustration/"
                        : `/illustration/${index + 1}/`
                    }
                  >
                    {index + 1}
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $slug: String!) {
    file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 600
          maxHeight: 600
          cropFocus: CENTER
          quality: 85
          traceSVG: { turnPolicy: TURNPOLICY_MINORITY, blackOnWhite: false }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    allContentfulIllustration(
      filter: { category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          category {
            slug
            name
          }
          title
          slug
          id
          heroImage {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
export default IllustrationCategoryArchive
